.publicsale_main{
    position: relative;
}
.publicsale{
    margin: 0 0 0 0;
    font-size: 56px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    text-align: center;
    padding-top: 20px;
}
.contract p{
    font-size: 30px;
    line-height: 1.33;

    margin-top: 20px;
    margin-bottom: 0;
}
.price p{
    font-size: 30px;
    line-height: 1.33;

    margin-top: 20px;
    margin-bottom: 0;	
}
.maininput{
	display: flex;
	margin: auto;
	margin-top: 20px;
	justify-content: center;
	align-items: center;
    font-size: 18px;
}
.text{
	position: relative;
	display: flex;
	align-items: center;

}

.buya{
	margin-left: 60px;
    -webkit-box-align: center;
    align-items: center;
    border: 0px;
    cursor: pointer;
    display: inline-flex;
    font-family: inherit;
    font-weight: 600;
    -webkit-box-pack: center;
    justify-content: center;
    letter-spacing: 0.03em;
    line-height: 1;
    opacity: 1;
    outline: 0px;
    transition: background-color 0.2s ease 0s, opacity 0.2s ease 0s;
    height: 52px;
    padding: 0px 36px;
    font-size: 18px;
    background: linear-gradient(270deg, rgba(188,131,52,1) 0%, rgba(188,131,52,1) 100%);
    color: rgb(14, 19, 33);
    border-radius: 12px;
    overflow: hidden;
    width: 100px;
}
.text_input{
	background-color: rgba(203,0,0,.25);
    border: 2px solid #abd176;
    border-radius: 12px;

    display: inline-block;
    font-size: 18px;
    font-weight: 200;
    height: 36px;
    outline: 0px;

    width: 100%;
    text-align: left;
    transition: all 0.3s ease 0s;

}
.text_bnb{
	font-size: 20px;	
	font-weight: 800;
}
.invited{
	margin-top: 20px;
	font-size: 20px;
}
.button{
    position: absolute;
    top: 2rem;
    right: 10rem;
    margin: auto;


}
.ido{
    position: relative;
 
}
.receivesnake{
    font-size: 30px;
    line-height: 1.33;

    margin-top: 20px;
    margin-bottom: 0;
}
.air{
    margin-left: 60px;
    -webkit-box-align: center;
    align-items: center;
    border: 0px;
    cursor: pointer;
    display: inline-flex;
    font-family: inherit;
    font-weight: 600;
    -webkit-box-pack: center;
    justify-content: center;
    letter-spacing: 0.03em;
    line-height: 1;
    opacity: 1;
    outline: 0px;
    transition: background-color 0.2s ease 0s, opacity 0.2s ease 0s;
    height: 52px;
    padding: 0px 36px;
    font-size: 18px;
    background: linear-gradient(270deg, rgba(188,131,52,1) 0%, rgba(188,131,52,1) 100%);
    color: rgb(14, 19, 33);
    border-radius: 12px;
    overflow: hidden;
    width: 100px;
    margin-top: 20px; 
    margin-bottom: 20px;   
}