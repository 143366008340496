.App {
  text-align: center;
  background-color: rgba(219, 232, 240, 1.0);
  font-size: 15px;
  color: rgb(14, 19, 33);
}
a {

  text-decoration: none;
}
p{
  word-break:normal;
  white-space:pre-warp;
  word-wrapL:break-word;
  word-wrap:break-word; 
}
img {
    vertical-align: middle;
    border-style: none;
}
video{
    object-fit: contain;
    overflow-clip-margin: content-box;
    overflow: clip;
    width: 100%;  
}
.main{
  position: relative;
}
.App_header{
  width: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: auto;
  background-color: transparent;
  top: 0;
  left: 0;
  z-index: 1000;
}

.header_logo{



  z-index: 1000;
}
.whitepaper{
  margin-left: 15%;
}
.buy{

}
.main_button{
  position: absolute;
  top: 40%;
  left: 30%;
  display: flex;
  align-items: center;
  z-index: 1000;
  justify-content: space-between;
}
.header_main{
  display: inline-flex;
  margin-left: 15rem;
  justify-content: center;
}
.game_b{
  margin-top: 15px;
  margin-bottom: 15px;
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 3px;
  text-transform: uppercase;
  color: #fff;
  padding: 15px 30px;
  grid-gap: 10px;
  gap: 10px;
  background: rgba(203,0,0,.25);
  border: 2px solid #abd176;
  -webkit-backdrop-filter: blur(12.5px);
  backdrop-filter: blur(12.5px);
  border-radius: 60px;
  cursor: pointer;
  display: flex;
  align-items: center;
  flex: 1;  
  margin-right: 3rem;
}
.game_c{
  margin-top: 15px;
  margin-bottom: 15px;
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 5px;
  text-transform: uppercase;
  color: #fff;
  padding: 15px 30px;
  grid-gap: 10px;
  gap: 10px;
  background: rgba(203,0,0,.25);
  border: 2px solid #abd176;
  -webkit-backdrop-filter: blur(12.5px);
  backdrop-filter: blur(12.5px);
  border-radius: 60px;
  cursor: pointer;
  display: flex;
  align-items: center;
  flex: 1;
  width: 100%;
}
.fllow{

  z-index: 1000;
}
.Footer_socialButtonLabel__3jlBO{
  font-size: 25px;

}
.header_logo{
  margin-top: 10px;
  margin-bottom: 10px;
}
.header_logo_img{
    width:100px;
    height:100px;
　　object-fit: fill; 
　　object-fit: contain; 
　　object-fit: cover;
　　object-fit: none;
　　object-fit: scale-down;
  

}
.footimg{
    width:100px;
    height:100px;
　　object-fit: fill; 
　　object-fit: contain; 
　　object-fit: cover;
　　object-fit: none;
　　object-fit: scale-down;  
}
.main{

 
  height:100%;
  width:100%;

  background-size:cover;

}
.title{
  width: 50%;
  height: 40%;

  background: url(./tit.png) no-repeat ;

  position: absolute;
  top: 20rem;
  left: 50%;
  margin-left: -30.42rem;
}

.game{
  display: flex;
}
.game_a{
  margin-top: 15px;
  margin-bottom: 15px;
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 6.5px;
  text-transform: uppercase;
  color: #fff;
  padding: 15px 30px;
  grid-gap: 10px;
  gap: 10px;
  background: rgba(203,0,0,.25);
  border: 2px solid #abd176;
  -webkit-backdrop-filter: blur(12.5px);
  backdrop-filter: blur(12.5px);
  border-radius: 60px;
  cursor: pointer;
  display: flex;
  align-items: center;
  flex: 1;
}

.play{
  width: 30px;
  height: 30px;
}






.nftbg{
  width: 100%;
  height: 1080px;
  background: url(./bg2.jpg) no-repeat;
  text-align: center;

}

.nft{
  width: 100%;
  height: 430px;
  align-items: center;
  display: inline-flex;
  justify-content: space-around;
  width: 90%;
}

img{
  border: none;
    vertical-align: middle;
    overflow-clip-margin: content-box;
    overflow: clip;
}
.nft img{
    width:500px;
    height:285px;
　　object-fit: fill; 
　　object-fit: contain; 
　　object-fit: cover;
　　object-fit: none;
　　object-fit: scale-dow;

}

.tokenomics_{
  margin: 0 0 0 0;
  font-size: 56px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  text-align: center;
  padding-top: 20px;

}
.tokenomics_detail{

  margin-bottom: 0;

  font-size: 30px;


}
.icon_a{
  background: rgb(255, 61, 63);
  position: relative;
  top: -1px;
  display: inline-block;
  width: 6px;
  height: 6px;
  vertical-align: middle;
  border-radius: 50%;
}
.icon_b{
  background: rgb(0, 152, 255);
  position: relative;
  top: -1px;
  display: inline-block;
  width: 6px;
  height: 6px;
  vertical-align: middle;
  border-radius: 50%;
}
.icon_c{
  background: rgb(152, 224, 255);
  position: relative;
  top: -1px;
  display: inline-block;
  width: 6px;
  height: 6px;
  vertical-align: middle;
  border-radius: 50%;
}
.icon_d{
  background: rgb(84, 193, 255);
  position: relative;
  top: -1px;
  display: inline-block;
  width: 6px;
  height: 6px;
  vertical-align: middle;
  border-radius: 50%;
}
.icon_e{
  background: rgb(255, 151, 58);
  position: relative;
  top: -1px;
  display: inline-block;
  width: 6px;
  height: 6px;
  vertical-align: middle;
  border-radius: 50%;
}


.token_p{
  display: flex; 
  align-items: center;
}
.tokenbg{
  background: url(./bg4.jpg) no-repeat;

}
.ant_row{

  row-gap: 0px;
  padding-top: 70px;
  padding-bottom: 70px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;  
  width: 50%;
  margin: auto;
}
.antcol{
  margin-bottom: 10px;
  margin-top: 20px;
}
.token_img{
  width: 50%;
}
.ant_image_img{
    width:100%;
    height:100%;
　　object-fit: fill; 
　　object-fit: contain; 
　　object-fit: cover;
　　object-fit: none;
　　object-fit: scale-down; 

}
.ant__col{
  display: block;
  align-items: center;
  justify-content: space-between;
}
.SectionThree_statsTitle__2ttjt{
  font-size: 30px;
  margin-left: 40px;
  font-weight: 500;
  text-align: left;
}
.SectionThree_MainHeading__2xaY{
  font-size: 30px;
}



.moblie_a{
  width: 100%;
}
.ant-row{
  margin-top: 50px;
  margin-bottom: 50px;
  row-gap: 0px;
  align-items: center;
  display: flex;


  justify-content: space-between;

}
.ant-col_{


}
.iphone{
  width: 90%;
  height: 380px;

  margin-top: 30px;
}

.ant-col{
  width: 40%;
  display: block;


}
.ido{
  background: url(./bg3.jpg) no-repeat;
 
}



.SectionFour_title__3XLJD{

  font-size: 50px;
  line-height: 1;
  font-weight: 500;

  margin-bottom: 0;
  text-align: left;
}
.SectionFour_info__L0sBY{

  font-size: 30px;
  line-height: 1.33;
  text-align: left;

  margin-top: 20px; 
  margin-bottom: 0;
}
.SectionFour_iconGroup__3ynhp{
  display: -webkit-flex;
  display: flex;
  margin-top: 20px;
}
.SectionFour_icon__3dCDC{
  width: auto;
  height: 30px;
  margin-right: 30px;
}






.Roadmap_{
  background: url(./bg2.jpg) no-repeat;

}
.roadmap{
  font-size: 56px;
  font-weight: 500;
  margin: 0 0 0 0;
  padding-bottom: 30px;
  padding-top: 20px;
}
.roadmap_span{
  font-size: 25px;

  padding-bottom: 30px;
  line-height: 1.67em;
  text-align: center;
}
.roadmapspan{

  display: inline-block;
}
.roadmap_Phase{
  display: flex;
  width: 70%;

  justify-content: space-around;
  align-items: flex-start;
  position: relative;
  margin: 0 auto;
  padding-bottom: 30px;
}
.Phase{

  border: 1px solid #9774d0;
  border-radius: 8px;

  display: inline-block;
  width: 30%;

}
.Phase_div{
  font-weight: 600;
  font-size: 40px;
  margin-top: 15px;
}
.Phase_ul{
  font-size: 15px;
  text-align: left;
}
.Phase_ul p{
  margin-left: 40px;
  margin-right: 40px;
  font-weight: 400;
  font-size: 25px;
}
.Team_{
  height: 100px;
}

span{
  line-height: 200%;
  word-wrap: break-word;
  text-align: left;

  padding: 5px;
}


.Footer_footerContainer__2MTT5{
}


.Footer_footerWrapper__pZQFt{
  width: 85%;
  margin: auto;
}
.ant_middle{
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: auto;
}
.footer_right{
  font-size: 20px;
  font-weight: 500;
}
.Footer_socialButton__1mnuI{
  align-items: center;
  display: flex;
  justify-content: space-around;
}
.social{
  width: 50px;
  margin: 5px;
}
.antdivider{
  display: flex;
  clear: both;
  width: 100%;
  min-width: 100%;

  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(181,36,36,.85);
  font-size: 12px;
  font-variant: tabular-nums;
  line-height: 1.66667;
  list-style: none;
  font-feature-settings: "tnum";
  border-top: 1px solid rgba(181,36,36,.06);

  border-top-color: #d6dadd;
  padding-bottom: 30px;
}
.Footer_logo__2Gjba{

}





.swiper{


    width: 100%;

}
.swiper_span{
    font-size: 25px;
    width: 58%;
    font-weight: 500;
    height: 40px;
    margin-bottom: 40px;

}
.group{
    height: 80px;
    width: 60%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    margin: auto;
    position: relative;

}
.groupa{
    animation-name: logoa;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-direction: normal;
    animation-duration: 15000ms;
    height: 80px;
    transform-origin: 0;
    display: flex;
}
.groupa_hidden{
    height: 80px;
    width: 100%;
    position: absolute;

    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

}
.log1{
    width: 80px;
    height: 80px;
}
@keyframes logoa{

0% {
    transform: translateX(1200px);
}

100% {
    transform: translateX(-1200px);
}
}
@media screen and (max-device-width:960px){
        .ant-col{
          display: block;
                  
          width: 90%;
          margin: auto;  
        }
        .ant-col_{
          display: block;
          margin: auto; 
        }
        .ant-row{
          display: block;
        }
        .ant__col{
          margin-left: 20px;
        }
        .ant_row{
          width: 100%;
          justify-content:center;
        }
        .ant_image{
          width: 300px;
        }
        .ant_image_img{
          width: 300px;
          height: 300px;
        }
        .nft{
          height: 200px;
        }
        .nftbg{
          background-size:cover;
          height: 60%;
        }
        .title{
          width: 35.85rem;
          height: 17.06rem;
          background: url(./tit.png) no-repeat;
          background-size: 100%;
          position: absolute;
          top: 10rem;
          left: 50%;
          margin-left: -16.42rem;
        }
        .roadmap_Phase{
          display: flex;
          width: 95%;
          justify-content: space-around;
          align-items: flex-start;
          position: relative;
          margin: 0 auto;
          padding-bottom: 30px;
        }
.nft img{
    width: 250px;
    height: 125px;
　　object-fit: fill; 
　　object-fit: contain; 
　　object-fit: cover;
　　object-fit: none;
　　object-fit: scale-dow
}
.Phase_ul p{
  margin-right: 0px;
}
.header_main{
  display: inline-flex;
  margin-left: 0;
  width: 100%;
  justify-content: center;
}
.App_header{
  justify-content: space-between;
  width: 100%;
  margin: auto;
}
.game_c{
  width: 75%;
}
}